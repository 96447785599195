import { initializeApp, getApps } from "firebase/app";
import { getAuth as getFirebaseAuth } from "firebase/auth";

export function initializeFirebase() {
  const defaultConfig = {
    apiKey: "AIzaSyDMFK7msIMlUjBPV5FXnplEb8NLUqHTmik",
    authDomain: "n34t-labs.firebaseapp.com",
    databaseURL: "https://n34t-labs.firebaseio.com",
    projectId: "n34t-labs",
    storageBucket: "n34t-labs.appspot.com",
    messagingSenderId: "382483155693",
    appId: "1:382483155693:web:9f465b4a6d6a5460",
  };

  return initializeApp(defaultConfig);
}

if (getApps().length === 0) {
  const app = initializeFirebase();
}

export const auth = getFirebaseAuth(getApps()[0]);
